var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-8 col-md-offset-2" }, [
    _vm.projects
      ? _c("div", { staticClass: "no-projects" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.projects.length > 0
            ? _c(
                "div",
                [
                  _vm._l(_vm.projects, function(project) {
                    return _c(
                      "div",
                      { key: project.id, staticClass: "card mb-2" },
                      [
                        _c("div", { staticClass: "card-header clearfix" }, [
                          _c("h4", { staticClass: "float-left" }, [
                            _vm._v(_vm._s(project.name))
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success btn-sm float-right",
                              attrs: {
                                disabled: _vm.counter.timer,
                                "data-toggle": "modal",
                                "data-target": "#timerCreate"
                              },
                              on: {
                                click: function($event) {
                                  _vm.selectedProject = project
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-play" })]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-body p-1" }, [
                          project.timers.length > 0
                            ? _c(
                                "ul",
                                { staticClass: "list-group" },
                                _vm._l(project.timers, function(timer) {
                                  return _c(
                                    "li",
                                    {
                                      key: timer.id,
                                      staticClass: "list-group-item clearfix"
                                    },
                                    [
                                      _c(
                                        "strong",
                                        { staticClass: "timer-name" },
                                        [_vm._v(_vm._s(timer.name))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "float-right" },
                                        [
                                          _vm.showTimerForProject(
                                            project,
                                            timer
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px"
                                                  }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.activeTimerString
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px"
                                                  }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.calculateTimeSpent(
                                                          timer
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                          _vm._v(" "),
                                          _vm.showTimerForProject(
                                            project,
                                            timer
                                          )
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-danger",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.stopTimer()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-stop"
                                                  })
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _c("p", [
                                _vm._v(
                                  'Nothing has been recorded for "' +
                                    _vm._s(project.name) +
                                    '". Click the play icon to record.'
                                )
                              ])
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "modal fade",
                      attrs: { id: "timerCreate", role: "dialog" }
                    },
                    [
                      _c("div", { staticClass: "modal-dialog modal-sm" }, [
                        _c("div", { staticClass: "modal-content" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("div", { staticClass: "modal-body" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newTimerName,
                                    expression: "newTimerName"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "usrname",
                                  placeholder: "What are you working on?"
                                },
                                domProps: { value: _vm.newTimerName },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.newTimerName = $event.target.value
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default btn-primary",
                                attrs: {
                                  "data-dismiss": "modal",
                                  disabled: _vm.newTimerName === "",
                                  type: "submit"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.createTimer(_vm.selectedProject)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "glyphicon glyphicon-play"
                                }),
                                _vm._v(" Start")
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ],
                2
              )
            : _c("div", [
                _c("h3", { attrs: { align: "center" } }, [
                  _vm._v("You need to create a new project")
                ])
              ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal fade",
              attrs: { id: "projectCreate", role: "dialog" }
            },
            [
              _c("div", { staticClass: "modal-dialog modal-sm" }, [
                _c("div", { staticClass: "modal-content" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newProjectName,
                            expression: "newProjectName"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "usrname",
                          placeholder: "Project Name"
                        },
                        domProps: { value: _vm.newProjectName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newProjectName = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default btn-primary",
                        attrs: {
                          "data-dismiss": "modal",
                          disabled: _vm.newProjectName == "",
                          type: "submit"
                        },
                        on: { click: _vm.createProject }
                      },
                      [_vm._v("Create")]
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      : _c("div", { staticClass: "timers" }, [
          _vm._v("\n        Loading...\n    ")
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("h2", { staticClass: "float-left project-title" }, [
          _vm._v("Projects")
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-sm float-right",
            attrs: { "data-toggle": "modal", "data-target": "#projectCreate" }
          },
          [_vm._v("New Project")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Record Time")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("New Project")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }